html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.multiTargetConfig p[class*="RaSimpleFormIterator-index"] {
  font-size: 2rem;
  font-weight: 600;
  color: #4f3cc9;
}
.darkTheme.multiTargetConfig p[class*="RaSimpleFormIterator-index"] {
  color: #ffffff;
}
.multiTargetConfig div:has(.ra-input) p[class*="RaSimpleFormIterator-index"] {
  font-size: unset;
  font-weight: unset;
  color: unset;
}
div[class*="MuiDrawer-root"] {
  height: unset;
}
div[class*="RaList-actions"] div[class*="MuiToolbar-root"] {
  justify-content: flex-start;
  width: 100%;
}
div[class*="RaList-actions"] > form {
  flex: unset;
}

div[class*="show-page"] > div,
div[class*="edit-page"] > div {
  justify-content: flex-start;
}

:root {
  --bulkBackgroundColor: rgb(219, 216, 244);
  --bulkButtonsColor: #4f3cc9;
}
/* hidden quest: find way to do darkTheme 
:html[style*="color-scheme: dark"] {
  --bulkBackgroundColor: rgb(100, 141, 174);
  --bulkButtonsColor: #ffffff;
} */

div[class*="list-page"] div[class*="MuiPaper-root"] div[data-test="bulk-actions-toolbar"] {
  justify-content: flex-start;
  background-color: var(--bulkBackgroundColor);
  color: var(--bulkButtonsColor);
}
div[class*="list-page"] div[class*="MuiPaper-root"] div[data-test="bulk-actions-toolbar"] button {
  color: var(--bulkButtonsColor);
}
